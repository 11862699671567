//rfce snippet=> react functional component with export
import React, { useState } from "react";
import {
    List,
    ListItem,
    ListItemText,
    Divider,
    Modal,
    Button,
    FormControl,
    Input,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import db from "../../firebase";

function getModalStyle() {
    return {
        top: "50%",
        left: "50%",
        transform: `translate(-50%, -50%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: 400,
        fontSize: "14px",
        backgroundColor: theme.palette.background.paper,
        border: "0px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function ToDo(props) {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [input, setInput] = useState(props.todo.todo);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const updateTodo = (e) => {
        db.collection("todos")
            .doc(props.todo.id)
            .set({ todo: input }, { merge: true });
        setOpen(false);
    };

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title">✍ Update ToDo</h2>
            <FormControl className="w-80">
                <Input
                    type="text"
                    value={input}
                    onChange={(event) => {
                        setInput(event.target.value);
                    }}
                ></Input>
            </FormControl>{" "}
            <span className="m-2">
                <Button
                    disabled={!input}
                    variant="contained"
                    color="primary"
                    onClick={(event) => updateTodo(event)}
                >
                    Update Todo
        </Button>
            </span>
        </div>
    );
    return (
        <React.Fragment>
            <List>
                <ListItem>
                    <ListItemText
                        primary={props.todo.todo}
                        secondary={`🕐 ${props.todo.timestamp?.toDate().toLocaleDateString("en")}`}
                    />
                    <EditIcon onClick={handleOpen} />
                    <DeleteForeverIcon
                        className="dangerous"
                        onClick={(event) =>
                            db.collection("todos").doc(props.todo.id).delete()
                        }
                    />
                </ListItem>
                <Divider />
            </List>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </React.Fragment>
    );
}

export default ToDo;
