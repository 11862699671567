import firebase from "firebase/app";
import("firebase/firestore");

const firebaseConfig = {
    apiKey: "AIzaSyBt7Duma6OPxAvB0rA0w4WbezTTLVA88Bw",
    authDomain: "todo-react-app-fb30b.firebaseapp.com",
    projectId: "todo-react-app-fb30b",
    storageBucket: "todo-react-app-fb30b.appspot.com",
    messagingSenderId: "833551486992",
    appId: "1:833551486992:web:cfbc50ac50e63b46b43a95"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();


export default db;