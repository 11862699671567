import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Input,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/firestore";

//Components
import ToDo from "./components/ToDo/ToDo";

import db from "./firebase";

import "./App.css";

function App() {
  const [todos, setTodos] = useState([]);
  const [input, setInput] = useState("");
  const addTodo = (event) => {
    event.preventDefault();
    // setTodos([...todos, input]);
    db.collection("todos").add({
      todo: input,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });
    setInput("");
  };
  useEffect(() => {
    db.collection("todos").orderBy("timestamp", "desc").onSnapshot((snapshot) => {
      setTodos(snapshot.docs.map((doc) => ({
        id: doc.id, todo: doc.data().todo,
        timestamp: doc.data().timestamp
      })));
    });
  }, []);
  return (
    <React.Fragment>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Typography variant="h6" color="inherit">
            🕐 ToDo App
          </Typography>
        </Toolbar>
      </AppBar>
      <Container className="m-5">
        <form>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={10}>
              <FormControl className="w-80">
                <InputLabel> 🕐 Write todo</InputLabel>
                <Input
                  type="text"
                  value={input}
                  onChange={(event) => {
                    setInput(event.target.value);
                  }}
                ></Input>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <Button
                type="submit"
                onClick={addTodo}
                disabled={!input}
                variant="contained"
                color="primary"
              >
                Add Todo
              </Button>
            </Grid>
          </Grid>
        </form>
        {todos.map((todo) => {
          return <ToDo key={todo.id} todo={todo} />;
        })}
      </Container>
    </React.Fragment>
  );
}

export default App;
